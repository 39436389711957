import * as React from "react";
import { graphql } from "gatsby";
import { Container, Segment, Grid, Header } from "semantic-ui-react";
import { LayoutProps, withLayout } from "../components/Layout";
import { MarkdownRemark } from "../graphql-types";

interface MeetupPostProps extends LayoutProps {
  data: {
    post: MarkdownRemark;
  };
}

const pageTitle = "Meetup";

const MeetupPostPage: React.FC<MeetupPostProps> = (props) => {
  const { html } = props.data.post;
  const { title, theme, slido, youtube }  = props.data.post.frontmatter;

  const youtubeURL = ((): string | undefined => {
    if (!youtube) {
      return undefined
    }
    const url = new URL(youtube);
    url.searchParams.set('autoplay', '1');
    return url.href;
  })()

  return (
    <Container>
      <Container fluid>
        <Grid doubling columns={2}>
          <Grid.Row>
            <Grid.Column width="11">
              <div className="youtube-embed-wrapper">
                {
                  youtubeURL
                    ?  <iframe title="YouTube Live" width="100%" height="100%" src={youtubeURL} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} />
                    : <div className="youtube-embed-placeholder">配信開始までしばらくお待ちください</div>
                }
              </div>
            </Grid.Column>

            <Grid.Column width="5">
              <div className="slido-embed-wrapper">
                <iframe title="Twitter" src={slido} height="125%" width="125%" frameBorder="0" style={{minHeight: "480px"}}></iframe>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container fluid>
        <Segment vertical>
          <Header as="h2">{theme} {title}</Header>

          <Segment vertical>
            <a href="https://twitter.com/intent/tweet?hashtags=CybozuTech" className="twitter-hashtag-button" data-show-count="false">#CybozuTech</a>
            <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
          </Segment>

          <Segment vertical
            style={{ border: "none" }}
            dangerouslySetInnerHTML={{
              __html: html,
            }}
          />
        </Segment>
      </Container>
    </Container>
  );
};

export default withLayout(MeetupPostPage, pageTitle);

export const pageQuery = graphql`
query TemplateMeetupsPost($slug: String!) {
  post: markdownRemark(fields: {slug: {eq: $slug}}) {
    html
    excerpt
    fields {
      slug
    }
    frontmatter {
      title
      theme
      slido
      youtube
    }
  }
}
`;
